import React, { useState} from 'react'
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';

const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
function App() {


  return (
    <div >
      <BrowserRouter>
          <React.Suspense fallback={loading}>
                <Switch>
                  <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
                </Switch>
            </React.Suspense>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
